import { useState } from "react";
import { DOMAIN } from "../lib/api.js";

export function Picture({
  card,
  main,
  artwork,
  index = 0,
  ratio = 3 / 4,
  ...rest
}) {
  const pic = artwork.pictureList[index];
  const id = pic && `artwork/${artwork.id}/${pic}`;
  const props = { id, ratio, ...rest };
  return main ? (
    <Image min={300} max={1500} {...props}>
      (min-width: 950px) calc((100vw - 5rem - 194px) / 2), (min-width: 800px)
      calc(100vw - 4rem - 194px) calc(100vw - 4rem)
    </Image>
  ) : card ? (
    <Image min={300} max={1000} {...props}>
      (max-width: 594px) calc((100vw - 4rem)), (max-width: 800px) calc((100vw -
      6rem) / 2), (max-width: 839px) calc((100vw - 4rem - 194px)), (max-width:
      1123px) calc((100vw - 6rem - 194px) / 2), 280px
    </Image>
  ) : (
    <Image min={300} max={1000} {...props}>
      6rem
    </Image>
  );
}

export function Image({ id, ratio, min, max, children, ...rest }) {
  const [loaded, setLoaded] = useState();
  return (
    <img
      loading="lazy"
      onLoad={(e) => {
        setLoaded(id);
        //checkSrcAccuracy(e.target);
      }}
      className={loaded && "loaded"}
      {...rest}
      {...attributes(id || "one", ratio, min || 300, max || 1800)}
      sizes={children}
    />
  );
}

function checkSrcAccuracy({ width, currentSrc }) {
  const pixels = width * window.devicePixelRatio;
  const step = unit(pixels);
  const expect = Math.round(pixels / step) * step;
  const actual = parseInt(currentSrc.split("/").pop().split("x")[0]);
  console.log({ width, expect, actual });
}

// 20 40 60 80 100 120 140 160 180 200
// 250 300 350 400 450 500 550 600
// 700 800 900 1000
// 1200 1400 1600 1800
function unit(n) {
  return n < 200 ? 20 : n < 600 ? 50 : n < 1000 ? 100 : 200;
}

function attributes(id, ratio, min, max) {
  const ss = [];
  for (let u = unit(min), w = Math.round(min / u) * u; w <= max; w += unit(w))
    ss.push(
      `https://images.${DOMAIN}/${id}/${w}x${Math.round(w * ratio) || ""} ${w}w`
    );
  return {
    width: min,
    height: Math.round(min * ratio) || null,
    srcSet: ss.join(", "),
  };
}
