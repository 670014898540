import { useEffect } from "react";
import { useFetcher } from "react-router-dom";
import useToast from "./Toast.js";
import Icon from "./Icon.js";

export default function ({
  name = "upload",
  method = "post",
  action,
  text,
  ...rest
}) {
  const formData = new FormData();
  const fetcher = useFetcher();
  const toast = useToast();

  useEffect(
    function () {
      if (fetcher.data?.error) toast(fetcher.data);
    },
    [fetcher.data]
  );

  function onChange(e) {
    formData.append("file", e.target.files[0]);
    fetcher.submit(formData, {
      action,
      method,
      encType: "multipart/form-data",
    });
  }
  return (
    <div {...rest}>
      <div
        className={text && "iconText"}
        style={{
          display: "flex",
          position: "relative",
        }}
      >
        {fetcher.state == "idle" ? (
          <Icon name={name} />
        ) : (
          <span className="spinner" />
        )}
        {text}
        <input type="file" value="" onChange={onChange} />
      </div>
    </div>
  );
}
