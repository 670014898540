const style = {
  color: "white",
  width: "2em",
  height: "2em",
  borderRadius: "50%",
};

export default function ({ person }) {
  const num = parseInt(person.id, 16) || 0;
  const text = person.name || person.email || person.role;
  return (
    <button
      className="avatar"
      style={{
        ...style,
        background: `hsl(${num % 360}deg 55% 75%)`,
      }}
    >
      {initial(text)}
      {initial(lastName(text))}
    </button>
  );
}

function initial(text) {
  return text.charAt(0).toUpperCase();
}

function lastName(text) {
  const words = text.split(" ");
  return words.length < 2 ? "" : words.pop();
}
