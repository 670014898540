import { useState } from "react";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";

import Icon from "../../components/Icon.js";

export default function ({ artwork }) {
  const [items, setItems] = useState(
    artwork.highlights?.map((text, i) => ({ text, id: i + 1 })) || []
  );

  return (
    <div>
      <label>Highlights</label>
      <div>
        <div className="field">
          <input
            placeholder="Press Enter To Add Highlights"
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                e.preventDefault(); // no submit
                if (e.target.value) {
                  setItems([
                    { text: e.target.value, id: new Date() - 0 },
                    ...items,
                  ]);
                  e.target.value = "";
                }
              }
            }}
          />
        </div>
        <DndContext
          sensors={useAppSensors()}
          onDragEnd={(e) => moveItem(e, setItems)}
        >
          <SortableContext items={items.map((item) => item.id)}>
            {items.map(({ id, text }, i) => (
              <Highlight
                key={id}
                id={id}
                text={text}
                remove={(e) => setItems(without(items, i))}
                name={`highlights[${i}]`}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}

function Highlight({ id, name, text, remove }) {
  const { setNodeRef, attributes, listeners, ...rest } = useSortable({ id });
  return (
    <>
      <div
        id={id}
        ref={setNodeRef}
        style={{
          position: "relative",
          padding: "0 1.5em",
          marginTop: ".5rem",
          ...draggable(rest),
        }}
      >
        <span className="iconText" style={{ position: "absolute", left: 0 }}>
          <Icon name="move" {...attributes} {...listeners} />
        </span>
        <Icon
          name="close"
          style={{ position: "absolute", right: 0 }}
          onClick={remove}
        />
        <input type="hidden" name={name} value={text} />
        {text}
      </div>
    </>
  );
}

function moveItem({ active, over }, setItems) {
  if (over?.id != active?.id)
    setItems((items) =>
      arrayMove(
        items,
        items.findIndex(({ id }) => id == active.id),
        items.findIndex(({ id }) => id == over.id)
      )
    );
}

function arrayMove(items, from, to) {
  const newItems = [...items];
  const [removed] = newItems.splice(from, 1);
  newItems.splice(to, 0, removed);
  return newItems;
}

function without(a, i) {
  a = [...a];
  a.splice(i, 1);
  return a;
}

function draggable({ transform, transition }) {
  if (transform)
    return {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      transition,
    };
}

function useAppSensors() {
  return useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
}
