import { useState, useEffect } from "react";
import { cognito } from "./lib/api.js";
import {
  redirect,
  useActionData,
  useLocation,
  Form,
  Link,
  Outlet,
} from "react-router-dom";

import Logo from "./components/Logo.js";
import Modal from "./components/Modal.js";
import { Field, Text, Submit } from "./components/Field.js";

export default [
  {
    path: "forgot-password",
    children: [
      {
        path: "privacy",
        element: <Privacy />,
      },
    ],
    element: <ForgotPassword />,
    action: async ({ request }) => {
      let pay = await payload(request);
      if (pay.ConfirmationCode) {
        let res =
          pay.Password == pay.Password2
            ? await cognito("ConfirmForgotPassword", pay)
            : { message: "Passwords did not match" };

        return res.message ? { ...pay, ...res } : redirect("/login");
      } else {
        let res = await cognito("ForgotPassword", pay);
        return res.message ? res : pay;
      }
    },
  },

  {
    path: "login",
    children: [
      {
        path: "privacy",
        element: <Privacy />,
      },
    ],
    element: <Login />,
    action: async ({ request }) => {
      let { Session, NEW_PASSWORD2, ...data } = await payload(request);
      let o = Session
        ? data.NEW_PASSWORD != NEW_PASSWORD2
          ? { message: "Passwords did not match" }
          : await cognito("RespondToAuthChallenge", {
              Session,
              ChallengeName: "NEW_PASSWORD_REQUIRED",
              ChallengeResponses: data,
            })
        : await cognito("InitiateAuth", {
            AuthFlow: "USER_PASSWORD_AUTH",
            AuthParameters: data,
          });

      if (o.AuthenticationResult) {
        for (const k in o.AuthenticationResult)
          localStorage.setItem(k, o.AuthenticationResult[k]);
        return redirect("/");
      }
      return { Session, ...o, ...data };
    },
  },
];

function Login() {
  const o = useActionData() || {};
  useEffect(
    function () {
      if (!o.Session) return;
      const inputs = document.getElementsByTagName("input");
      inputs[1].value = "";
      inputs[0].value = "";
      inputs[0].focus();
    },
    [o]
  );
  return o.Session ? (
    <User>
      <div>
        <h2>Change Password</h2>
        <p>Please choose a new password</p>
      </div>
      <NewPassword autoFocus name="NEW_PASSWORD" label="Password" />
      <Text
        required
        type="password"
        name="NEW_PASSWORD2"
        label="Confirm Password"
      />
      <input type="hidden" name="Session" defaultValue={o.Session} />
      <input type="hidden" name="USERNAME" defaultValue={o.USERNAME} />
    </User>
  ) : (
    <User>
      <div>
        <h2>Welcome</h2>
        <p>Please login to continue</p>
      </div>
      <Text
        label="Email"
        type="email"
        name="USERNAME"
        autoComplete="username"
        required
        autoFocus
      />
      <Text
        required
        type="password"
        name="PASSWORD"
        label="Password"
        autoComplete="current-password"
      />
    </User>
  );
}

function ForgotPassword() {
  let { Username } = useActionData() || {};
  return !Username ? (
    <User>
      <div>
        <h2>Forgot Password</h2>
        <p>A verification code will be sent to your email</p>
      </div>
      <Text autoFocus required type="email" name="Username" label="Email" />
    </User>
  ) : (
    <User>
      <div>
        <h2>Change Password</h2>
        <p>Please enter your verification code</p>
      </div>
      <input type="hidden" name="Username" value={Username} />
      <Text required name="ConfirmationCode" />
      <NewPassword autoFocus name="Password" autoComplete="new-password" />
      <Text
        required
        type="password"
        name="Password2"
        label="Confirm Password"
        autoComplete="new-password"
      />
    </User>
  );
}

const SpecialCharacters = "@ ! # $ % & * + -";

function NewPassword({ name, label, props }) {
  const [value, setValue] = useState("");
  const minLength = 8;
  const constraints = {
    [`${minLength} characters`]: value.length >= minLength,
    "A-Z": /[A-Z]/.test(value),
    "a-z": /[a-z]/.test(value),
    "0-9": /\d/.test(value),
    [SpecialCharacters]: value.match(
      new RegExp(`[${SpecialCharacters.split(" ").join("")}]`)
    ),
  };
  const names = Object.keys(constraints);
  return (
    <div>
      <Field name={name} label={label}>
        <input
          required
          type="password"
          name={name}
          {...props}
          onChange={(e) => setValue(e.target.value)}
          className={names.find((name) => !constraints[name]) && "invalid"}
        />
      </Field>
      <div className="pills">
        {names.map((name) => (
          <span
            key={name}
            className={constraints[name] ? "green sub pill" : "sub pill"}
          >
            {name}
          </span>
        ))}
      </div>
    </div>
  );
}

function User({ children }) {
  const { pathname } = useLocation();
  return (
    <>
      <Form
        method="post"
        style={{
          padding: ".5rem",
          minHeight: "100vh",
          background: "var(--pink)",
          display: "grid",
          placeItems: "center",
        }}
      >
        <section
          className="card"
          style={{
            width: "100%",
            maxWidth: "52ch",
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          <h1>
            <Logo />
          </h1>
          {children}
          <Submit wide text="OK" />
          <p>
            {pathname == "/login" ? (
              <Link to="/forgot-password">Forgot Password?</Link>
            ) : (
              <Link to="/login">Login</Link>
            )}
          </p>
          <p>
            <Link to="privacy" className="sub blue">
              Privacy Policy
            </Link>
          </p>
        </section>
      </Form>
      <Outlet />
    </>
  );
}

async function payload(request) {
  const text = await request.text();
  const data = new URLSearchParams(text);
  return Object.fromEntries(data);
}

function Privacy() {
  return (
    <Modal>
      <iframe
        src="https://policies.sdamp.art/privacy.html"
        style={{
          display: "block",
          width: "50em",
          height: "calc(100vh - 2rem)",
          maxWidth: "calc(100vw - 2rem)",
        }}
      />
    </Modal>
  );
}
