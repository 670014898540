import React, { useState } from "react";
import { Link, redirect, useLoaderData } from "react-router-dom";
import { useData } from "../index.js";
import { Admin, Breadcrumb, EditForm } from "./index.js";
import { ListHeading, useFilters, uniques } from "./components/List.js";
import Table from "./components/Table.js";
import MultiSelect from "./components/MultiSelect.js";
import Avatar from "../components/Avatar.js";
import Icon from "../components/Icon.js";
import { Tiles } from "../components/Artwork.js";
import { Field, Text } from "../components/Field.js";
import { Expand, Paragraphs } from "../components/Expand.js";
import { api } from "../lib/api.js";
import payload from "../lib/payload.js";

export default {
  path: "artists",
  children: [
    {
      path: "",
      element: <ListArtists />,
      loader: async () => await api("/gallery/artists"),
    },
    {
      path: "add",
      element: <EditArtist />,
      action: async function ({ request, params }) {
        const o = await api("/gallery/artists", "post", await payload(request));
        return o.error ? o : redirect(`/artists/${o.id}`);
      },
    },
    {
      path: ":id",
      loader: async ({ params }) => await api(`/gallery/artists/${params.id}`),
      children: [
        { path: "", element: <ViewArtist /> },
        {
          path: "edit",
          element: <EditArtist />,
          action: async function ({ request, params }) {
            const o = await api(
              `/gallery/artists/${params.id}`,
              "put",
              await payload(request)
            );
            return o.error ? o : redirect(`/artists/${params.id}`);
          },
        },
      ],
    },
  ],
};

function ListArtists() {
  let data = useLoaderData();
  const [items, fields] = useFilters(data, ["technique"]);
  return (
    <section>
      <ListHeading type="Artist" />
      {fields}
      <Table
        items={items}
        Name={(c) => (
          <div className="imageLeft">
            <Link to={c.id}>
              <Avatar person={c} />
            </Link>
            <div>
              <strong>
                <Link to={c.id}>{c.name}</Link>
              </strong>
              <div className="sub">{representedSince(c)}</div>
            </div>
          </div>
        )}
        Technique={(c) => (
          <span className="sub line2">{(c.technique || []).join(", ")}</span>
        )}
        Works={(c) => (
          <p className="blue">{c.stats && c.stats.numberOfWorks}</p>
        )}
      />
    </section>
  );
}

export function ViewArtist() {
  const artist = useData();
  return (
    <section>
      <div className="row">
        <Breadcrumb subs={[artist.name]} />
        {artist.canEdit && (
          <Link to="edit">
            <button className="fat iconText">
              <Icon name="edit" />
              Edit
            </button>
          </Link>
        )}
      </div>
      <Artist />
      {artist.selectedWorks?.length ? <h3>Selected Works</h3> : ""}
      <Tiles artworks={artist.selectedWorks || []} />
    </section>
  );
}

export function Artist() {
  let artist = useData();
  return (
    <>
      <div className="imageLeft">
        <Avatar person={artist} />
        <h3>{artist.name}</h3>
      </div>
      <Admin>
        {!artist.canEdit && (
          <div className="bubble" style={{ padding: "1em" }}>
            This artist is managed by another gallery
          </div>
        )}
      </Admin>
      <div className="details">
        {artist.location && (
          <>
            <Icon name="location" />
            <span>Location: {artist.location}</span>
          </>
        )}
        {artist.representDate && (
          <>
            <Icon name="date" />
            {representedSince(artist)}
          </>
        )}
        {artist.technique?.length > 0 && (
          <>
            <Icon name="medium" />
            <span>Technique: {artist.technique.join(", ")}</span>
          </>
        )}
      </div>

      {artist.bio && (
        <Expand name="About">
          <Paragraphs>{artist.bio}</Paragraphs>
        </Expand>
      )}
    </>
  );
}

function representedSince({ representDate }) {
  const year = new Date(representDate).getFullYear();
  if (year) return `Represented since ${year}`;
}

const Today = new Date().toISOString().split("T")[0];

function EditArtist() {
  const data = useData() || {};
  const id = data.id;
  const fd = (name, type) => ({
    name,
    type,
    defaultValue: data[name],
  });
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(data.technique || []);

  React.useEffect(function () {
    (async function () {
      setOptions(uniques(await api("/gallery/artists"), "technique"));
    })();
  }, []);

  return (
    <EditForm>
      <Breadcrumb subs={id ? [data.name, "Edit"] : ["Add"]} />
      <Text
        required
        type="text"
        {...fd("name")}
        autoFocus={!id}
        readOnly={!!id}
      />
      <Text {...fd("email", "email")} />
      <Text {...fd("location")} />
      <Text {...fd("representDate", "date")} max={Today} />
      <MultiSelect
        name="technique"
        {...{ options, selected, setSelected, setOptions }}
      />
      <Field label="About">
        <textarea {...fd("bio")} />
      </Field>
    </EditForm>
  );
}
