import { useEffect } from "react";
import { Form, Link, useNavigate } from "react-router-dom";
import { Submit } from "./Field.js";
import Icon from "./Icon.js";

export default Modal;

export function ConfirmDelete({ name, title }) {
  return (
    <Confirm method="delete" name="Confirm Delete">
      <p>
        Are you sure you want to permanently delete{" "}
        <strong>{name || title}?</strong>
      </p>
    </Confirm>
  );
}

export function Confirm({ name, children, ...rest }) {
  return (
    <Modal {...rest}>
      <dialog>
        <h2>{name}</h2>
        {children}
        <p style={{ color: "var(--error)" }}>This action cannot be reversed</p>
        <Submit wide text="Yes, I’m sure" />
        <p>
          <Link to="..">
            <strong>No, go back!</strong>
          </Link>
        </p>
      </dialog>
    </Modal>
  );
}

export function Modal({ children, close = true, ...rest }) {
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("keydown", keydown);
    return () => document.removeEventListener("keydown", keydown);
  }, []);

  function keydown(e) {
    if (e.key == "Escape") navigate("..");
  }

  return (
    <div
      onClick={(e) => close && navigate("..")}
      style={{
        position: "fixed",
        zIndex: 1,
        top: 0,
        left: 0,
        display: "grid",
        placeItems: "center",
        width: "100%",
        height: "100%",
        overflowY: "auto",
        background: "rgba(0,0,0,0.4)",
      }}
    >
      <Form
        autoComplete="off"
        spellCheck="off"
        method="post"
        {...rest}
        onClick={(e) => e.stopPropagation()}
        style={{
          position: "relative",
          background: "white",
          borderRadius: "var(--curvy)",
          margin: "1rem",
        }}
      >
        {children}
        {close && (
          <Link
            to=".."
            style={{
              display: "flex",
              position: "absolute",
              top: ".5rem",
              right: ".5rem",
              opacity: 0.8,
              fontSize: 13,
              padding: ".3em",
              background: "white",
              borderRadius: "50%",
            }}
          >
            <Icon name="close" />
          </Link>
        )}
      </Form>
    </div>
  );
}
