import { Fragment } from "react";
import {
  NavLink,
  Navigate,
  Outlet,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import { ErrorElement, useData } from "./index.js";
import { Artwork, Cards, Tiles } from "./components/Artwork.js";
import { Artist } from "./gallery/artists.js";
import UserButton from "./components/UserButton.js";
import Document, { DOCUMENTS } from "./components/Document.js";
import Icon from "./components/Icon.js";
import Logo from "./components/Logo.js";
import { api } from "./lib/api.js";
import { address, formatDate, galleryName, humanize } from "./lib/text.js";

const children = [
  {
    path: "artworks/:id",
    element: (
      <section>
        <Artwork />
      </section>
    ),
    loader: async ({ params }) => await api(`/collector/artworks/${params.id}`),
  },
  {
    path: "artists/:id",
    loader: async ({ params }) => await api(`/collector/artists/${params.id}`),
    element: (
      <section>
        <Artist />
      </section>
    ),
  },
];

export default {
  path: "collector",
  loader: async () => await api("/collector"),
  element: <Layout />,
  children: [
    {
      errorElement: <ErrorElement />,
      children: [
        {
          index: true,
          element: <Navigate to="discover" />,
        },
        {
          path: "discover",
          children: [
            ...children,
            {
              index: true,
              loader: async () =>
                await api("/collector/artworks/recommendations"),
              element: <Discover />,
            },
          ],
        },
        {
          path: "collection",
          children: [
            ...children,
            {
              index: true,
              loader: async () => api("/collector/artworks"),
              element: <Collection />,
            },
          ],
        },
        {
          path: "profile",
          loader: async () => api("/collector/memberships"),
          element: <Profile />,
        },
      ],
    },
  ],
};

function Layout() {
  const data = useLoaderData();
  const user = { role: "collector", ...data };
  return (
    <div id="app">
      <Header>
        <UserButton user={user} />
      </Header>
      <main className="hasBar">
        <div>
          <Outlet context={user} />
        </div>
        <nav
          className="bar"
          id="bottomBar"
          style={{
            display: "flex",
            overflow: "hidden",
            justifyContent: "space-around",
          }}
        >
          <NavLinks
            style={({ isActive }) => ({
              display: "flex",
              alignItems: "center",
              borderRadius: "2em",
              padding: "0.6em 1em",
              margin: "0.5em 0",
              transition: "all 300ms",
              color: isActive && "var(--pink)",
              background: isActive ? "rgb(251, 242, 242)" : "white",
            })}
          />
        </nav>
      </main>
      <div id="side" className="nav">
        <Logo />
        <NavLinks />
      </div>
    </div>
  );
}

function NavLinks({ style }) {
  const icon = {
    width: "0.8rem",
    height: "auto",
  };
  return (
    <>
      <NavLink style={style} className="iconText" to="/collector/discover">
        <Icon style={icon} name="discover" />
        Discover
      </NavLink>
      <NavLink style={style} className="iconText" to="/collector/collection">
        <Icon style={icon} name="artworks" />
        Collection
      </NavLink>
      <NavLink style={style} className="iconText" to="/collector/profile">
        <Icon style={icon} name="identity" />
        Profile
      </NavLink>
    </>
  );
}

function Header({ children }) {
  const { pathname } = useLocation();
  const page = useData();
  const segs = pathname.split("/");
  const basePath = `/${segs[1]}/${segs[2]}`;
  return (
    <header className="bar row">
      <NavLink
        to={basePath}
        className="blue iconText"
        style={{ visibility: pathname == basePath && "hidden" }}
      >
        <Icon name="arrow_left" />
        Back
      </NavLink>
      <strong
        className="line1"
        style={{
          position: "absolute",
          left: "3.5rem",
          right: "3.5rem",
          textAlign: "center",
        }}
      >
        {page?.name || page?.title || humanize(segs[2])}
      </strong>
      {children}
    </header>
  );
}

function Discover() {
  const artworks = useLoaderData();
  return (
    <section>
      <Cards artworks={artworks} basePath="/collector/discover" />
    </section>
  );
}

function Collection() {
  const artworks = useLoaderData();
  const grouped = {};
  const groups = [];
  for (const artwork of artworks) {
    grouped[artwork.gallery] || groups.push((grouped[artwork.gallery] = []));
    grouped[artwork.gallery].push(artwork);
  }
  return (
    <section>
      {groups.map((artworks) => (
        <Fragment key={artworks[0].id}>
          <h3>{humanize(artworks[0].gallery.split("-").slice(1).join(" "))}</h3>
          <Tiles purchased basePath="artworks" artworks={artworks} />
        </Fragment>
      ))}
    </section>
  );
}

function Profile() {
  const memberships = useLoaderData();
  return (
    <section>
      <ul className="cards">
        {memberships.map((membership, index) => (
          <li key={index}>
            <div />
            <div>
              <h3>{galleryName(membership.gallery)}</h3>
              {membership.joinedDate && (
                <>
                  <small>Joined {formatDate(membership.joinedDate)}</small>
                </>
              )}
            </div>
            <div className="details">
              {address(membership) && (
                <>
                  <Icon name="location" />
                  <div>
                    <strong>Your Address</strong>
                    <br />
                    <small>{address(membership)}</small>
                  </div>
                </>
              )}
              {DOCUMENTS.map((name) => (
                <Document
                  strong
                  nonEmpty
                  key={name}
                  name={name}
                  person={membership}
                />
              ))}
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}
