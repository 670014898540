import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Picture } from "./Image.js";
import Icon from "./Icon.js";

// How far to place icons from the edge:
const OFFSET = ".5rem";

const TINT = {
  opacity: 0.5,
  color: "white",
  background: "black",
  position: "absolute",
  padding: ".5em",
};

const CONTROL = {
  ...TINT,
  borderRadius: "50%",
  display: "flex", // make it square
};

const STYLES = {
  container: {
    scrollbarWidth: "none",
    scrollBehavior: "smooth",
    scrollSnapType: "x mandatory",
    overflowX: "scroll",
    display: "flex",
  },

  item: {
    scrollSnapAlign: "start",
    width: "100%",
    flexShrink: 0,
  },

  lightbox: {
    ...CONTROL,
    top: OFFSET,
    right: OFFSET,
  },

  arrow: (right) => ({
    ...CONTROL,
    top: "50%",
    left: !right && OFFSET,
    right: right && OFFSET,
    transform: `translateY(-50%) rotate(${right ? 180 : 0}deg)`,
  }),
};

function getIndex(img) {
  return parseInt(img?.getAttribute("data-index"));
}

export default function ({ artwork, lightbox }) {
  const scrollContainerRef = useRef();
  const currentImg = useVisible(scrollContainerRef);
  const currentIndex = getIndex(currentImg);

  const [loaded, setLoaded] = useState([]);
  function onLoad({ target }) {
    setLoaded((loaded) => {
      const items = [...loaded];
      loaded.includes(target) || items.push(target);
      return items.sort((a, b) => getIndex(a) - getIndex(b));
    });
  }

  // Pre-load everything as soon as the lead image is done:
  const loading = loaded.length ? "eager" : "lazy";
  const prev = loaded[currentIndex - 1];
  const next = loaded[currentIndex + 1];

  if (artwork.pictureList?.length)
    return (
      <div
        onLoad={onLoad}
        className="carousel"
        style={{
          overflow: "hidden",
          position: "relative",
          borderRadius: "var(--curvy)",
        }}
      >
        <div ref={scrollContainerRef} style={STYLES.container}>
          {artwork.pictureList.map((id, index) => (
            <Picture
              main
              style={STYLES.item}
              loading={loading}
              key={index}
              index={index}
              artwork={artwork}
              data-index={index}
            />
          ))}
        </div>
        <Arrow target={prev} />
        <Arrow target={next} right />
        {lightbox && (
          <Link to={`picture/${currentIndex}`} style={STYLES.lightbox}>
            <Icon name="search" />
          </Link>
        )}
        <Bar images={loaded} current={loaded[currentIndex]} />
        <style>{`
            .carousel *::-webkit-scrollbar { display: none }
            .carousel img { border-radius:0 }
          `}</style>
      </div>
    );
}

function Bar({ images, current }) {
  if (images.length > 1)
    return (
      <div
        className="group"
        style={{
          ...TINT,
          bottom: 0,
          left: 0,
          right: 0,
          textAlign: "center",
        }}
      >
        {images.map((target, key) => (
          <button
            key={key}
            onClick={(e) => scrollIntoView(target)}
            style={{
              padding: ".25em",
              borderRadius: "50%",
              border: "2px solid currentColor",
              background: target == current && "currentColor",
            }}
          />
        ))}
      </div>
    );
}

function Arrow({ target, right }) {
  if (target)
    return (
      <button
        style={STYLES.arrow(right)}
        onClick={(e) => scrollIntoView(target)}
      >
        <Icon name={"arrow_left"} />
      </button>
    );
}

function scrollIntoView(target) {
  target.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "start",
  });
}

function useVisible(containerRef) {
  const [currentImg, setCurrentImg] = useState();
  useEffect(callback, []);
  return currentImg;

  function callback() {
    const onChange = (entries) =>
      entries.forEach(
        (entry) => entry.isIntersecting && setCurrentImg(entry.target)
      );
    const container = containerRef.current;
    const observer = new IntersectionObserver(onChange, {
      root: container,
      threshold: 0.9,
    });
    container
      ?.querySelectorAll("img")
      .forEach((item) => observer.observe(item));
  }
}
