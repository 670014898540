import Icon from "./Icon.js";

export default function () {
  return (
    <span className="logo iconText">
      <Icon name="s" style={{ height: "2.5em" }} />
      <Icon name="sdamp" style={{ height: "1em", margin: 0 }} />
    </span>
  );
}
