import React, { useState } from "react";
import {
  Link,
  Outlet,
  redirect,
  useActionData,
  useLoaderData,
} from "react-router-dom";
import { useData } from "../index.js";
import { Breadcrumb, EditForm, AppLink } from "./index.js";
import Table from "./components/Table.js";
import MultiSelect from "./components/MultiSelect.js";
import { ListHeading, useFilters } from "./components/List.js";
import Avatar from "../components/Avatar.js";
import Icon from "../components/Icon.js";
import Upload from "../components/Upload.js";
import { Tiles } from "../components/Artwork.js";
import Document, { DOCUMENTS } from "../components/Document.js";
import { Text, Radios } from "../components/Field.js";
import { ConfirmDelete } from "../components/Modal.js";
import payload from "../lib/payload.js";
import { api } from "../lib/api.js";
import { ADDRESS, address, formatDate } from "../lib/text.js";

export default {
  path: "collectors",
  children: [
    {
      path: "",
      element: <List />,
      loader: async () => await api("/gallery/collectors"),
    },
    {
      path: "add",
      element: <Add />,
      action: async function ({ request, params }) {
        let { id, ...pay } = await payload(request);
        if (id) {
          const o = await api("/gallery/collectors/" + id + "/onboard", "POST");
          return o.error ? o : redirect(`/collectors/${id}`);
        }
        if (pay.name) {
          const o = await api("/gallery/collectors", "POST", pay);
          return o.id ? redirect(`/collectors/${o.id}`) : o;
        }
        if (pay.email) {
          const o = await api("/gallery/collectors/query", "POST", pay);
          return o.status == 404 ? pay : o;
        }
      },
    },
    {
      path: ":id",
      loader: async ({ params }) =>
        await api(`/gallery/collectors/${params.id}`),
      children: [
        {
          path: "",
          element: <View />,
        },
        {
          path: "edit",
          element: <Information />,
          action: async function ({ request, params }) {
            const p = `/collectors/${params.id}`;
            const o = await api(`/gallery${p}`, "put", await payload(request));
            return o.error ? o : redirect(p);
          },
        },
        {
          path: "documents",
          element: (
            <>
              <Documents />
              <Outlet />
            </>
          ),
          children: [
            {
              path: "proof-of-id",
              element: <ConfirmDelete name="Proof of ID" />,
              action: documentsAction,
            },
            {
              path: "proof-of-address",
              element: <ConfirmDelete name="Proof of Address" />,
              action: documentsAction,
            },
            {
              path: "terms-and-conditions",
              element: <ConfirmDelete name="Terms and Conditions" />,
              action: documentsAction,
            },
          ],
        },
      ],
    },
  ],
};

const COLLECTOR_TYPES = ["INDIVIDUAL", "BUSINESS"];

function Add() {
  const o = useActionData();
  return (
    <EditForm Back={o} text={o ? "Add Collector" : "Continue"}>
      <Breadcrumb subs={["Add"]} />
      {!o || o.error ? (
        <Text required type="email" name="email" defaultValue="" />
      ) : o.id ? (
        <>
          <input type="hidden" name="id" value={o.id} />
          <Profile {...o} />
        </>
      ) : (
        <>
          <Text readOnly type="email" name="email" defaultValue={o.email} />
          <Text autoFocus required name="name" />
          <Radios
            name="type"
            value={COLLECTOR_TYPES[0]}
            values={COLLECTOR_TYPES}
          />
        </>
      )}
    </EditForm>
  );
}

async function documentsAction({ request, params }) {
  const type = request.url.split("/").pop();
  const path = `/gallery/collectors/${params.id}/docs/${type}`;
  const body = await request.formData();
  const o = await api(path, request.method, body);
  return o.error ? o : redirect(`/collectors/${params.id}/documents`);
}

function List() {
  let data = useLoaderData();
  const [items, fields] = useFilters(data, ["interests", "type"]);
  return (
    <section>
      <ListHeading type="Collector" />
      {fields}
      <Table
        items={items}
        Name={Profile}
        Interests={(o) => <span className="sub line2">{Interests(o)}</span>}
        Joined={(o) => Year(o.joinedDate)}
      />
    </section>
  );
}

const Interests = (o) =>
  o.interests &&
  o.interests
    .map((name) => {
      const n = o.stats && o.stats.collectedByArtist[name];
      return n ? `${name} (${n})` : name;
    })
    .join(", ");

function View(props) {
  let collector = useData();
  let info = (
    <section>
      <Profile {...collector} />
      <div className="details">
        {collector.joinedDate && (
          <>
            <Icon name="date" />
            <span>Joined: {formatDate(collector.joinedDate)}</span>
          </>
        )}
        {collector.interests?.length > 0 && (
          <>
            <Icon name="artists" />
            <span>Interests: {collector.interests.join(",")}</span>
          </>
        )}
        {address(collector) && (
          <>
            <Icon name="location" />
            <span>Address: {address(collector)}</span>
          </>
        )}
        {collector.phone && (
          <>
            <Icon name="phone" />
            <span>Phone: {collector.phone}</span>
          </>
        )}
      </div>
    </section>
  );
  return (
    <section>
      <div className="row">
        <Breadcrumb subs={[collector.name]} />
        {collector.canEdit && (
          <Link to="edit">
            <button className="fat iconText">
              <Icon name="edit" />
              Edit
            </button>
          </Link>
        )}
      </div>
      {Object.keys(collector.documents || {}).length < 1 ? (
        info
      ) : (
        <div className="up2">
          {info}
          <section className="bubble">
            <h3>Documents</h3>
            <div className="details">
              {DOCUMENTS.map((name) => (
                <Document
                  nonEmpty
                  strong
                  key={name}
                  name={name}
                  person={collector}
                />
              ))}
            </div>
          </section>
        </div>
      )}
      {collector.collection?.length > 0 && (
        <>
          <h2>Collection</h2>
          <Tiles purchased artworks={collector.collection} />
        </>
      )}
    </section>
  );
}

function Profile({ id, type, name, email, amlStatus }) {
  return (
    <div className={amlStatus ? "verified imageLeft" : "imageLeft"}>
      <Link to={"/collectors/" + id}>
        <Avatar person={{ id, name }} />
      </Link>
      <div>
        <div className="group">
          <strong>
            <Link to={"/collectors/" + id}>{name}</Link>
          </strong>
          {amlStatus && (
            <Icon
              name="shield"
              style={{
                color: "#6CC070",
              }}
            />
          )}
          {type == "BUSINESS" && (
            <Icon name="biz" style={{ height: "1.2em" }} />
          )}
        </div>
        <div className="sub">{email}</div>
      </div>
    </div>
  );
}

function Information() {
  const collector = useData();
  const [interests, setInterests] = useState(collector.interests || []);

  const fd = (name) => ({
    name,
    defaultValue: collector[name],
  });

  const checkbox = (name) => ({
    name,
    type: "checkbox",
    defaultChecked: collector[name],
  });

  // Interests options:
  const [artistNames, setArtistNames] = useState([]);
  React.useEffect(function () {
    (async function () {
      const artists = await api("/gallery/artists");
      setArtistNames(artists.map((a) => a.name));
    })();
  }, []);

  return (
    <EditForm method="put">
      <Breadcrumb subs={[collector.name, "Information"]} />
      <Tabs />
      <Text readOnly {...fd("name")} />
      <Text readOnly {...fd("email")} />
      <Text {...fd("phone")} />
      <Text {...fd("joinedDate")} type="date" max={today()} />
      <MultiSelect
        name="interests"
        options={artistNames}
        selected={interests}
        setSelected={setInterests}
      />
      <div style={{ alignItems: "center" }}>
        <label>AML Verification</label>
        <input className="field" {...checkbox("amlStatus")} />
      </div>
      <hr />
      <h2>Address</h2>
      {ADDRESS.map((name) => (
        <Text key={name} {...fd(name)} />
      ))}
    </EditForm>
  );
}

function Documents() {
  const collector = useData();
  return (
    <form>
      <section>
        <Breadcrumb subs={[collector.name, "Documents"]} />
        <Tabs />
        {DOCUMENTS.map((name) => (
          <div key={name} className="row">
            <div className="details">
              <Document strong name={name} person={collector} />
            </div>
            <div style={{ textAlign: "left" }}>
              <Upload
                action={`/collectors/${collector.id}/documents/${name}`}
                className="sub blue"
                text="Upload"
              />
              <div
                style={{ visibility: !collector.documents[name] && "hidden" }}
              >
                <Link to={name} className="sub blue iconText">
                  <Icon name="close" style={{ color: "var(--error)" }} />
                  Remove
                </Link>
              </div>
            </div>
          </div>
        ))}
      </section>
    </form>
  );
}

function Tabs() {
  return (
    <div style={{ borderBottom: "1px solid var(--lite)" }}>
      {["edit", "documents"].map((n) => (
        <strong key={n}>
          <AppLink
            to={"../" + n}
            style={({ isActive }) => ({
              color: isActive && "#CD6363",
              borderBottom: isActive && "2px solid currentColor",
              paddingBottom: "1em",
              marginRight: "1.4rem",
              marginBottom: "-2px",
              display: "inline-block",
            })}
          >
            {n == "edit" ? "Information" : "Documents"}
          </AppLink>
        </strong>
      ))}
    </div>
  );
}

const today = () => new Date().toISOString().split("T")[0];

function Year(o) {
  //o = '2022-11-11T11:49:52.32282Z'
  if ((o = o && o.match(/\d{4}/))) return o[0];
  //if (o = new Date(o)) return o.getFullYear() || null
}
