import Icon from "./Icon.js";
import { formatDate } from "../lib/text.js";

const PROPS = {
  "proof-of-address": "Proof of Address",
  "proof-of-id": "Proof of ID",
  "terms-and-conditions": "Terms and Conditions",
};

export const DOCUMENTS = Object.keys(PROPS);

export default function ({ name, person, strong, nonEmpty }) {
  const doc = person.documents[name];
  const content = doc ? (
    <a target="_blank" href={doc.url}>
      {PROPS[name]}
    </a>
  ) : (
    PROPS[name]
  );
  if (doc || !nonEmpty)
    return (
      <>
        <Icon name={name} style={{ height: "auto", width: "1.5em" }} />
        <span>
          {strong ? <strong>{content}</strong> : content}
          <br />
          {doc && <small>Updated {formatDate(doc.lastModified)}</small>}
        </span>
      </>
    );
}
