import { useReducer, useState } from "react";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { Image } from "../../components/Image.js";
import Icon from "../../components/Icon.js";
import { api } from "../../lib/api.js";

export default function ({ id, pictureList = [] }) {
  const [items, setItems] = useState(
    // Initialize sortable ids:
    pictureList.map((picId, i) => ({
      id: "pic" + i,
      picId,
      path: `artwork/${id}/${picId}`,
    }))
  );

  // Update count for the number of uploads we are waiting on
  const [busy, dispatchBusy] = useReducer((n, k) => n + k, 0);

  async function addFile(file) {
    const body = new FormData();
    body.append("file", file);
    dispatchBusy(1);
    const o = await api("/tmp/pictures", "POST", body);
    dispatchBusy(-1);
    if (o.error) return;
    setItems((items) => [
      ...items,
      {
        id: new Date() - 0,
        picId: o.id,
        path: `tmp/${o.id}`,
      },
    ]);
  }
  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        for (const file of e.dataTransfer.files) addFile(file);
      }}
    >
      <DndContext
        sensors={useAppSensors()}
        onDragEnd={(e) => moveItem(e, setItems)}
      >
        <SortableContext items={items.map(({ id }) => id)}>
          <div className="pictures">
            {items.map((item, i) => (
              <Thumb
                {...item}
                key={i}
                name={`pictureList[${i}]`}
                remove={(e) => setItems(without(items, i))}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <div
        className="bubble iconText"
        style={{ position: "relative", overflow: "hidden", padding: ".5rem" }}
      >
        <strong>
          {busy ? <div className="spinner" /> : <Icon name="plus" />}
          Add files
        </strong>
        <input type="file" onChange={async (e) => addFile(e.target.files[0])} />
      </div>
    </div>
  );
}

function Thumb({ name, id, picId, path, remove }) {
  const { setNodeRef, attributes, listeners, ...rest } = useSortable({ id });
  return (
    <>
      <div id={id} ref={setNodeRef} style={draggable(rest)}>
        <input type="hidden" name={name} value={picId} />
        <Image
          id={path}
          min={100}
          max={400}
          ratio={3 / 4}
          {...listeners}
          {...attributes}
        >
          5rem
        </Image>
        <button
          type="button"
          onClick={remove}
          style={{ position: "absolute", top: "-0.5em", right: "-0.5em" }}
        >
          <Icon
            name="close"
            style={{
              padding: ".25em",
              borderRadius: "50%",
              background: "var(--lite)",
            }}
          />
        </button>
      </div>
    </>
  );
}

function moveItem({ active, over }, setItems) {
  if (over?.id != active?.id)
    setItems((items) =>
      arrayMove(
        items,
        items.findIndex(({ id }) => id == active.id),
        items.findIndex(({ id }) => id == over.id)
      )
    );
}

function arrayMove(items, from, to) {
  const newItems = [...items];
  const [removed] = newItems.splice(from, 1);
  newItems.splice(to, 0, removed);
  return newItems;
}

function without(a, i) {
  a = [...a];
  a.splice(i, 1);
  return a;
}

function draggable({ transform, transition }) {
  if (transform)
    return {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      transition,
    };
}

function useAppSensors() {
  return useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
}
