import { createContext, useContext, useState } from "react";
import {
  Form,
  Navigate,
  NavLink,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import artists from "./artists.js";
import artworks from "./artworks.js";
import collectors from "./collectors.js";

import { NotFound, ErrorElement } from "../index.js";
import Logo from "../components/Logo.js";
import Icon from "../components/Icon.js";
import UserButton from "../components/UserButton.js";
import { Submit } from "../components/Field.js";
import { Modal } from "../components/Modal.js";
import { humanize } from "../lib/text.js";
import { parseJwt } from "../lib/api.js";

export default {
  loader: getUser,
  element: <Layout />,
  children: [
    {
      errorElement: <ErrorElement />,
      children: [
        { path: "*", element: <NotFound /> },
        { path: "/", element: <Navigate to="artworks" /> },
        { path: "dashboard", element: <Navigate to="/" /> },
        artists,
        artworks,
        collectors,
      ],
    },
  ],
};

export const AppLink = Link;
const DirtyContext = createContext([]);

function Layout() {
  const user = useLoaderData();
  const navigate = useNavigate();
  const [menuOn, setMenuOn] = useState(false);

  const [dirty, setDirty] = useState(false);
  const [href, setHREF] = useState(false);

  if (user.role == "collector") return <Navigate to="/collector/discover" />;

  return (
    <DirtyContext.Provider value={[dirty, setDirty, setHREF]}>
      <div
        id="app"
        className={menuOn ? "menuOn" : ""}
        onClick={() => menuOn && setMenuOn(false)}
      >
        <header className="bar row">
          <strong className="iconText">
            <Icon name="artworks" />
            {user.gallery}
          </strong>
          <div className="group">
            <UserButton user={user} />
            <Icon
              name="menu"
              onClick={(e) => {
                e.preventDefault();
                setMenuOn(!menuOn);
              }}
            />
          </div>
        </header>
        <main>
          <Outlet context={user} />
          {href && (
            <Modal close={false}>
              <dialog>
                <h2>Unsaved Changes</h2>
                <p>
                  Are you sure you want to leave this page? Your changes will be
                  lost.
                </p>
                <button
                  className="fat commit"
                  style={{ marginBottom: "0.5rem" }}
                  onClick={(e) => {
                    navigate(href);
                    setHREF(false);
                    setDirty(false);
                  }}
                >
                  Discard
                </button>
                <button className="fat" onClick={(e) => setHREF(false)}>
                  Cancel
                </button>
              </dialog>
            </Modal>
          )}
        </main>
        <div className="nav" id="side">
          <Logo />
          {["collectors", "artists", "artworks"].map((name) => (
            <Link key={name} to={`/${name}`} className="iconText">
              <Icon name={name} />
              {humanize(name)}
            </Link>
          ))}
        </div>
      </div>
    </DirtyContext.Provider>
  );
}

export function Admin({ children }) {
  const { role } = useOutletContext();
  if (role == "gallery admin") return children;
}

function Link({ children, ...rest }) {
  const [dirty, setDirty, setHREF] = useContext(DirtyContext);
  return (
    <NavLink
      {...rest}
      onClick={(e) => {
        const href = e.target.getAttribute("href");
        if (href && dirty) {
          e.preventDefault();
          setHREF(href);
        }
      }}
    >
      {children}
    </NavLink>
  );
}

export function EditForm({ Back, text, children, method = "post" }) {
  const setDirty = useContext(DirtyContext)[1];
  return (
    <Form
      method={method}
      className="hasBar"
      onChange={(e) => setDirty(true)}
      onSubmit={(e) => setDirty(false)}
    >
      <section className="edit">{children}</section>
      <div className="bar group" style={{ textAlign: "right" }}>
        <Link to={Back ? "." : ".."} className="fat">
          {Back ? "Back" : "Cancel"}
        </Link>
        <Submit text={text} />
      </div>
    </Form>
  );
}

export function Breadcrumb({ subs = [] }) {
  const { pathname } = useLocation();
  let segs = pathname.split("/");
  return (
    <nav className="line1 breadcrumb">
      <Link to={"/" + segs[1]}>{humanize(segs[1])}</Link>
      {subs.map((name, i) => {
        const to = segs.slice(0, i + 3).join("/");
        return (
          <span key={i}>
            <Icon name="arrow_right" style={{ height: 12, margin: "0 .8em" }} />
            {i == subs.length - 1 ? (
              <strong>{name}</strong>
            ) : (
              <Link key={i} to={to}>
                {name}
              </Link>
            )}
          </span>
        );
      })}
    </nav>
  );
}

export function getUser() {
  const { name, email, ...claims } = parseJwt(localStorage.getItem("IdToken"));
  const user = { name, email };
  for (const group of claims["cognito:groups"] || []) {
    const [key, ...rest] = group.split("-");
    user[key] = rest.join(" ");
    user[group] = true;
  }
  return user;
}
