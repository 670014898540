import { useState } from "react";
import { Field } from "../../components/Field.js";
import Icon from "../../components/Icon.js";

export default function ({
  label,
  name,
  prop,
  selected,
  setSelected,
  options,
  setOptions,
}) {
  const text = (o) => ("string" == typeof o ? o : o.name);
  const [input, setFocus] = useState(false);
  const [term, setTerm] = useState("");
  const exp = new RegExp(term, "i");
  const matches = options.filter(
    (o) => exp.test(text(o)) && !selected.includes(o)
  );
  const map = {};
  for (const v of options) map[text(v).toLowerCase()] = true;

  function addValue(o) {
    setSelected([...selected, o]);
    setTerm("");
    input.blur();
  }

  return (
    <Field
      name={name}
      label={label}
      menu={
        !input || (!setOptions && !matches.length) ? (
          false
        ) : (
          <>
            {matches.map((o, i) => (
              <a key={i} onClick={(e) => addValue(o)}>
                {text(o)}
                {o.email && (
                  <>
                    <br />
                    <small>{o.email}</small>
                  </>
                )}
              </a>
            ))}
            {term && setOptions && !map[term.toLowerCase()] && (
              <a
                className="iconText"
                onClick={(e) => {
                  setOptions([term, ...options]);
                  addValue(term);
                }}
              >
                <Icon name="plus" />
                Add "{term}"
              </a>
            )}
          </>
        )
      }
    >
      <div style={{ position: "relative", flexGrow: "2" }}>
        <div className="multi" autoComplete="off" spellCheck="off">
          {selected.map((o, i) => (
            <a key={i} className="pill">
              <span>
                {text(o)}
                {o.email && (
                  <>
                    <br />
                    <small>{o.email}</small>
                  </>
                )}
              </span>
              <input
                type="hidden"
                name={`${name}[${i}]`}
                value={prop ? o[prop] : o}
              />
              <Icon
                name="close"
                style={{ marginRight: -8 }}
                onClick={(e) => setSelected(without(selected, i))}
              />
            </a>
          ))}
          <input
            type="text"
            value={term}
            onKeyDown={(e) => e.key == "Enter" && e.preventDefault()}
            onChange={(e) => setTerm(e.target.value)}
            onFocus={(e) => setFocus(e.target)}
            onBlur={(e) => setFocus(false)}
          />
        </div>
      </div>
    </Field>
  );
}

function without(a, i) {
  a = [...a];
  a.splice(i, 1);
  return a;
}
