import { useState, useEffect } from "react";

export default function ({ button, children }) {
  const [open, setOpen] = useState();
  const [clickedButton, setClickedButton] = useState();

  function clickButton(e) {
    setOpen(!open);
    setClickedButton(true);
  }

  function clickDocument(e) {
    setClickedButton((clickedButton) => {
      clickedButton || setOpen(false);
      return false;
    });
  }

  useEffect(() => {
    document.addEventListener("click", clickDocument);
    return () => document.removeEventListener("click", clickDocument);
  }, []);

  return (
    <div
      className="hasMenu"
      onClick={clickButton}
      style={{
        display: "inline-block",
        position: "relative",
        zIndex: open ? 1 : 0,
      }}
    >
      {button(open)}
      {open && (
        <div
          className="menu card"
          style={{
            position: "absolute",
            top: "102%",
            right: 0,
            whiteSpace: "nowrap",
            textAlign: "left",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
