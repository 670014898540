const { hostname } = window.location;
export const DOMAIN = hostname.endsWith(".sdamp.art")
  ? hostname
  : "app.dev.sdamp.art";

const ClientId =
  DOMAIN == "app.sdamp.art"
    ? "2pch8bicpkrh4um7tn4vsnptc1" // prod: sdamp-users-v3
    : "4uqaeo01cucehb4cg52ioi3c24"; // dev: sdamp-users-v3

// 401 Unauthorized: token is missing / bad / expired (login)
// 403 Forbidden: token is good, wrong permissions, (no login)
// eg: Caller is not authorized to perform this action!
function Unauthorized(error) {
  throw { error, status: 401 };
}

export async function api(path, method = "get", body) {
  // const num = (Math.random() * 100) | 0;
  // if (num > 50) throw new Error(`Random explosion ${num}!`);

  const headers = {
    Authorization: `Bearer ${await getIdToken()}`,
  };

  if (isObject(body)) {
    body = JSON.stringify(body);
    headers["Content-Type"] = "application/json";
  }

  const res = await fetch(`https://api.${DOMAIN}${path}`, {
    method,
    body,
    headers,
  });

  const o = await res.json();
  o.status = res.status;

  // Navigate to /login for Unauthorized
  // Throw to Error Page if we are in a loader:
  if (o.status == 401 || (o.error && method == "get")) throw o;

  // Show toast:
  return o;
}

function isObject(value) {
  return Object.prototype.toString.call(value) == "[object Object]";
}

async function getIdToken() {
  const { IdToken, RefreshToken } = localStorage;
  if (!IdToken) Unauthorized("Missing ID token");
  if (parseJwt(IdToken).exp > new Date() / 1000) return IdToken;
  if (!RefreshToken) Unauthorized("Missing refresh token");
  return await refreshToken(RefreshToken);
}

async function refreshToken(REFRESH_TOKEN) {
  const { AuthenticationResult, message } = await cognito("InitiateAuth", {
    AuthFlow: "REFRESH_TOKEN_AUTH",
    AuthParameters: { REFRESH_TOKEN },
  });
  if (message) Unauthorized(message);
  const { IdToken } = AuthenticationResult;
  localStorage.setItem("IdToken", IdToken);
  return IdToken;
}

export async function cognito(id, options) {
  const res = await fetch("https://cognito-idp.eu-west-2.amazonaws.com", {
    method: "POST",
    body: JSON.stringify({
      ...options,
      ClientId,
    }),
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target": "AWSCognitoIdentityProviderService." + id,
    },
  });
  return await res.json();
}

export function parseJwt(token) {
  try {
    var base64URL = token.split(".")[1];
    var base64 = base64URL.replace(/-/g, "+").replace(/_/g, "/");

    return JSON.parse(
      decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      )
    );
  } catch ({ message }) {
    Unauthorized("Error parsing token");
  }
}
