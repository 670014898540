import { useReducer } from "react";
import { Link, useLocation } from "react-router-dom";
import { useData } from "../index.js";
import Carousel from "./Carousel.js";
import Icon from "./Icon.js";
import Upload from "./Upload.js";
import { Picture } from "./Image.js";
import { Paragraphs, Expand, useExpander } from "./Expand.js";
import { galleryName, humanize, formatDate } from "../lib/text.js";

export function Artwork({ lightbox }) {
  const artwork = useData();
  const [showPrice, togglePrice] = useReducer((value) => !value, true);

  const dot = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "50%",
    background: availabilityColour(artwork),
  };
  const dots = (
    <div
      style={{
        position: "relative",
        padding: 5,
        marginLeft: "1rem",
      }}
    >
      <div style={{ ...dot, transform: "scale(2)", opacity: 0.3 }} />
      <div style={dot} />
    </div>
  );
  return (
    <>
      <div className="up2 artwork-details" style={artworkStyle(artwork)}>
        <section>
          <Carousel lightbox={lightbox} artwork={artwork} />
          <div>
            <div className="row">
              <h2>
                <em>{artwork.title}</em>
              </h2>
              {dots}
            </div>
            <p>
              <ArtistLink artwork={artwork} />
            </p>
            <div>{Tags(artwork)}</div>
          </div>

          <div className="details">
            {artwork.creationYear && (
              <>
                <Icon name="date" />
                <span>Created: {artwork.creationYear}</span>
              </>
            )}
            {artwork.dimension && (
              <>
                <Icon name="dimension" />
                <span>Size: {artwork.dimension}</span>
              </>
            )}
            {artwork.medium.length > 0 && (
              <>
                <Icon name="medium" />
                <span>Medium: {artwork.medium.join(", ")}</span>
              </>
            )}
            {artwork.storageRef && (
              <>
                <Icon name="storageRef" />
                <span>Reference: {artwork.storageRef}</span>
              </>
            )}
          </div>

          {artwork.price && (
            <p className="group">
              <button className="blue" onClick={togglePrice}>
                {showPrice ? "Hide Price: " : "Show Price"}
              </button>
              {showPrice && <span>{artwork.price}</span>}
            </p>
          )}
          {artwork.description && (
            <Expand name="Description">
              <Paragraphs>{artwork.description}</Paragraphs>
            </Expand>
          )}
        </section>
        <section>
          {artwork.highlights.length ? (
            <h3 className="iconText">
              <Icon name="palette" /> Highlights
            </h3>
          ) : (
            ""
          )}
          {artwork.highlights.map((s, i) => (
            <p key={i}>{s}</p>
          ))}
          {artwork.provenance.length || artwork.draft ? (
            <>
              <h3 className="iconText">
                <Icon name="collectors" /> Provenance
              </h3>
              {artwork.provenance.map((o, i) => (
                <Ownership
                  key={i}
                  current={i == 0}
                  draft={artwork.draft}
                  {...o}
                />
              ))}
            </>
          ) : (
            ""
          )}
        </section>
      </div>
    </>
  );
}

export function Cards({ artworks, basePath = "" }) {
  return (
    <ul className="cards">
      {artworks.map((a) => (
        <li key={a.id} style={artworkStyle(a)}>
          <Link
            to={`${basePath}${artworkPath(a)}`}
            style={{ position: "relative" }}
          >
            <Picture card artwork={a} />
          </Link>
          <strong>
            <em>
              <Link to={`${basePath}${artworkPath(a)}`}>{a.title}</Link>
            </em>
          </strong>
          <div style={{ flexGrow: 1 }}>
            <ArtistLink artwork={a} />
            <YearAndMedium {...a} />
          </div>
          <div className="sub pink">
            {a.dimension && (
              <p className="iconText">
                <Icon name="dimension" />
                {a.dimension}
              </p>
            )}
          </div>
          <div>
            <Tags {...a} />
          </div>
          <span
            style={{
              position: "absolute",
              bottom: 0,
              left: "1rem",
              margin: 0,
              width: 100,
              height: 4,
              borderRadius: 2,
              background: availabilityColour(a),
            }}
          />
        </li>
      ))}
    </ul>
  );
}

export function Tiles({ purchased, artworks, basePath = "/artworks" }) {
  return (
    <div className="tiles">
      {artworks.map((a) => (
        <div key={a.id} style={{ display: "flex" }}>
          <div style={{ width: "45%", paddingRight: "1rem" }}>
            <Link to={`${basePath}/${a.id}`}>
              <Picture artwork={a} />
            </Link>
          </div>
          <div style={{ width: "55%" }}>
            {purchased && (
              <p className="sub blue">
                Purchased {formatDate(a.ownershipDate)}
              </p>
            )}
            <p>
              <strong>
                <Link to={`${basePath}/${a.id}`}>{a.title}</Link>
              </strong>
            </p>
            <p>
              <ArtistLink artwork={a} />
            </p>
            <YearAndMedium {...a} />
            <p>
              <Tags {...a} />
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

function ArtistLink({ artwork }) {
  const { pathname } = useLocation();
  const segs = pathname.split("/");
  const to = `${
    segs[1] == "collector" ? `/${segs[1]}/${segs[2]}` : ""
  }/artists/${artwork.artistId}`;
  return (
    <Link to={to}>
      {artwork.artistName}
      {artwork.gallery &&
        segs[2] == "discover" &&
        `, ${galleryName(artwork.gallery)}`}
    </Link>
  );
}

function Tags(artwork) {
  return (
    <>
      {artwork.draft && <Tag>Draft</Tag>}
      {artwork.owners?.length > 1 && <Tag type="green">Shared Ownership</Tag>}
      {artwork.frameStatus != "N/A" && (
        <Tag type={artwork.frameStatus == "Framed" && "framed"}>
          {artwork.frameStatus}
        </Tag>
      )}
    </>
  );
}

function YearAndMedium(o) {
  let Y = o.creationYear,
    a = Y ? [Y] : [];
  if (o.medium) a = [...a, ...o.medium];
  if ((a = a.join(", "))) return <p className="line2">{a}</p>;
}

function Tag({ children, type = "orange", ...rest }) {
  if (children)
    return (
      <span className={`pill ${type}`} {...rest}>
        {children}
      </span>
    );
}

// Grey-out page / card when Unavailable:
const artworkStyle = ({ availability }) => ({
  filter: availability == "UNAVAILABLE" && "grayscale(.95)",
});

const availabilityColour = ({ availability }, a = 1) =>
  availability == "SOLD"
    ? `rgba(205, 99, 99, ${a})`
    : availability == "ON_HOLD"
    ? `rgba(219, 164, 58, ${a})`
    : "none";

const artworkPath = (a) => "/artworks/" + a.id + (a.draft ? "!" : "");

function Ownership(o) {
  const artwork = useData();
  const editable = artwork.canEdit && (o.current || o.draft);

  const [expandButton, expanderProps] = useExpander({
    style: {
      position: "absolute",
      top: 0,
      right: 0,
      lineHeight: 0,
      padding: ".2rem",
    },
  });
  return (
    <>
      <div className="ownership" style={{ position: "relative" }}>
        {
          /* attachments is null for previous ownerships when viewing as collector: */
          o.attachments && expandButton
        }
        <div className="row">
          <div>
            <div className="sub pink">
              {formatDate(o.ownershipDate || new Date())}
            </div>
            <div>
              {o.owners
                .map((owner) => owner.name || "Private Collector")
                .join(", ")}
            </div>
          </div>
          {o.current && <div className="pill green">Current</div>}
        </div>
        <div className="attachments" {...expanderProps}>
          {o.lop && (
            <Attachment
              name={humanize("lop")}
              path={
                o.draft &&
                `${artworkPath(artwork)}/ownerships/${o.ownershipDate}/lop`
              }
              {...o.lop}
            />
          )}
          {o.coa && (
            <Attachment
              {...o.coa}
              name={humanize("coa")}
              path={
                o.draft &&
                `${artworkPath(artwork)}/ownerships/${o.ownershipDate}/coa`
              }
            />
          )}
          {Object.keys(o.attachments || []).map((key) => (
            <Attachment
              key={key}
              {...o.attachments[key]}
              path={
                editable &&
                `${artworkPath(artwork)}/ownerships/${
                  o.ownershipDate
                }/attachment/${key}`
              }
            />
          ))}
          {editable && <hr />}
          <div className="row">
            {editable && (
              <Link
                to={`ownerships/${o.ownershipDate}`}
                className="sub blue iconText"
              >
                <Icon name="attach" />
                Attach
              </Link>
            )}
            {false && (
              <Link className="sub pink iconText">
                <Icon name="bin" />
                Delete
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function Attachment({ path, ...o }) {
  return (
    <>
      <hr />
      <div>
        <div className="row" style={{ lineHeight: 1.3 }}>
          <a target="_blank" href={o.url}>
            <strong>{o.name}</strong>
            {o.lastModified && (
              <>
                <br />
                <small>Updated {formatDate(o.lastModified)}</small>
              </>
            )}
          </a>
          <div
            className="group"
            style={{ fontSize: "75%", whiteSpace: "nowrap" }}
          >
            {path && <Upload name="change" action={path} method="put" />}
            {path && (
              <Link to={path}>
                <Icon name="bin" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
