import { useState } from "react";

export default function ({ items, Name, ...rest }) {
  const keys = Object.keys(rest);
  const [ord, setOrd] = useState(1);
  items.sort((a, b) => ord * a.name.localeCompare(b.name));
  const Arrow = ({ value, className }) => (
    <div style={{ padding: ".2em", opacity: value == ord ? 1 : 0.2 }}>
      <div className={className} />
    </div>
  );
  return items.length < 1 ? (
    <p>No results</p>
  ) : (
    <div
      style={{
        overflow: "hidden",
        borderRadius: 8,
        border: "1px solid var(--lite)",
      }}
    >
      <table>
        <thead>
          <tr>
            <th>
              Name
              <div
                onClick={(e) => setOrd(ord < 0 ? 1 : -1)}
                style={{
                  display: "inline-block",
                  margin: "-.2em .3em",
                  verticalAlign: "middle",
                }}
              >
                <Arrow value={-1} className="arrow-up" />
                <Arrow value={1} className="arrow-down" />
              </div>
            </th>
            {keys.map((text) => (
              <th key={text}>{text.replace("-", " ")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) => (
            <tr key={i}>
              <td>{Name(item)}</td>
              {keys.map((text, j) => (
                <td key={j}>{rest[text](item, i)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
