import { useEffect } from "react";
import { useLocation, useNavigation } from "react-router-dom";

export default function ({ getElement }) {
  const { state } = useNavigation();
  const { key } = useLocation();

  useEffect(() => {
    if (state == "loading") sessionStorage.setItem(key, getElement().scrollTop);

    if (state == "idle")
      getElement().scrollTop = parseInt(sessionStorage.getItem(key)) || 0;
  }, [state, key]);
}
