import { useState, useRef } from "react";
import Icon from "./Icon.js";

export function Paragraphs({ children }) {
  return children?.split(/\n\n+/).map((p, i) => <p key={i}>{p}</p>);
}

export function Expand({ name, children }) {
  const [expandButton, expanderProps] = useExpander({}, "auto");
  return (
    <div className="expander">
      <div className="row">
        <h3>{name}</h3>
        {expandButton}
      </div>
      <section {...expanderProps}>{children}</section>
    </div>
  );
}

export function useExpander(buttonProps, defaultHeight = 0) {
  const [height, setHeight] = useState(defaultHeight);
  const ref = useRef();
  function toggle() {
    if (height) requestAnimationFrame(() => setHeight(0));
    setHeight(ref.current.scrollHeight);
  }
  return [
    <button onClick={toggle} {...buttonProps}>
      <Icon
        name={height ? "arrow_up" : "arrow_down"}
        style={{ width: ".5rem", height: "auto" }}
      />
    </button>,
    {
      ref,
      onTransitionEnd: (e) => height && setHeight("auto"),
      style: { height, transition: "height 200ms", overflow: "hidden" },
    },
  ];
}
