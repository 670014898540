// Not implemented: iOS Safari 14 / OS X Safari 13
// "The operation is not supported" (asyncFunctionResume)
// encType: application/x-www-form-urlencoded (default)
export default async function payload(request) {
  const text = await request.text();
  const data = new URLSearchParams(text);
  return unpackData(Object.fromEntries(data));
}

function unpackData(x) {
  const o = {};
  for (const k in x) {
    let m = k.match(/^([^\[]+)\[(\d+)\]$/);
    if (m) {
      const [text, name, index] = m;
      o[name] = o[name] || [];
      o[name][index] = x[k];
    } else if (k == "amlStatus") {
      o[k] = !!x[k];
    } else {
      o[k] = x[k];
    }
  }
  return o;
}
