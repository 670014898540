import { useState } from "react";
import { Link } from "react-router-dom";
import { Admin } from "../index.js";
import Icon from "../../components/Icon.js";
import { Field, Text } from "../../components/Field.js";

export function ListHeading({ type }) {
  return (
    <div className="row">
      <h2>{type}s</h2>
      <Admin>
        <Link to="add">
          <button className="fat iconText">
            <Icon name="plus" />
            Add {type}
          </button>
        </Link>
      </Admin>
    </div>
  );
}

export function useFilters(items, filterNames) {
  const [filters, setFilters] = useState({});
  const [term, setTerm] = useState("");
  const exp = new RegExp(term, "i");
  function matchFilters(a) {
    for (const name in filters)
      if (
        filters[name] != "All" &&
        (!a[name] || !a[name].includes
          ? filters[name] != a[name]
          : !a[name].includes(filters[name]))
      )
        return;
    return true;
  }
  return [
    items &&
      items.filter &&
      items.filter(
        (a) => exp.test(a.title || a.name || "") && matchFilters(a, filters)
      ),
    <div
      style={{
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "repeat(auto-fit, minmax(12em, 1fr))",
      }}
    >
      <Text
        name="Name"
        type="search"
        value={term}
        autoComplete="off"
        placeholder="Search by name"
        onChange={(e) => setTerm(e.target.value)}
      />
      {(filterNames || []).map((key) => (
        <Field key={key} name={key}>
          <select
            onChange={(e) => setFilters({ ...filters, [key]: e.target.value })}
          >
            {["All"].concat(uniques(items, key)).map((v, i) => (
              <option key={i}>{v}</option>
            ))}
          </select>
        </Field>
      ))}
    </div>,
  ];
}

// Get all the unique strings that exist for the combined values of a list (of artists / artworks / collectors)
// Looking at a single property value for each item in the list:
// Collectors have interests
// Artists have technique
// Artworks have a medium
export function uniques(items, prop) {
  const values = [];
  const seen = {};
  const see = (s) => (seen[s] = seen[s] || values.push(s));
  for (const data of items) each(data[prop]);
  return values;
  function each(v) {
    if (typeof v == "string") see(v);
    if (Array.isArray(v)) for (const value of v) see(value);
  }
}
