import React from "react";
import ReactDOM from "react-dom/client";
import { register } from "./serviceWorkerRegistration.js";

import {
  RouterProvider,
  Navigate,
  Outlet,
  createBrowserRouter,
  useLocation,
  useMatches,
  useRouteError,
} from "react-router-dom";

import user from "./user.js";
import gallery from "./gallery/index.js";
import collector from "./collector.js";

import ScrollRestoration from "./components/ScrollRestoration.js";
import { ToastProvider } from "./components/Toast.js";

import "./css/base.css";
import "./css/layout.css";
import "./css/app.css";

// Reload the page as soon as an update is installed:
register();
const { serviceWorker } = navigator;
if (serviceWorker) {
  serviceWorker.addEventListener("controllerchange", () => {
    console.log("Reloading..");
    window.location.reload();
  });
}

const router = createBrowserRouter([
  {
    errorElement: <ErrorElement />,
    children: [...user, gallery, collector],
    element: (
      <ToastProvider>
        <Outlet />
        <ScrollRestoration
          getElement={() =>
            document.querySelector(".hasBar > :not(.bar)") ||
            document.querySelector("main") ||
            document.body
          }
        />
      </ToastProvider>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

export function ErrorElement() {
  const { pathname } = useLocation();
  const { status, error, message } = useRouteError();
  return status == 404 ? (
    <NotFound message={error} />
  ) : status == 401 ? (
    <Navigate to="/login" />
  ) : (
    <section>
      <div>
        <h2>Something went wrong</h2>
        <p>{error || message}</p>
      </div>
      <img src="/500.png" style={{ background: "white", maxWidth: 500 }} />
    </section>
  );
}

export function NotFound({
  message = "The requested page could not be found",
}) {
  return (
    <section>
      <div>
        <h2>Page Not Found</h2>
        <p>{message}</p>
      </div>
      <img src="/404.png" style={{ background: "white", maxWidth: 500 }} />
    </section>
  );
}

export function useData() {
  // Find the Route with path: ':id'
  for (const m of useMatches())
    if (m.params.id == m.pathname.split("/").pop()) return m.data;
}
