import { useNavigate } from "react-router-dom";
import Menu from "./Menu.js";
import Avatar from "./Avatar.js";
import { humanize } from "../lib/text.js";

export default function ({ user }) {
  const navigate = useNavigate();
  function logout() {
    localStorage.clear();
    navigate("/login");
  }
  if (user.role)
    return (
      <Menu button={(open) => <Avatar person={user} />}>
        <div style={{ padding: ".5rem" }}>
          <strong>{user.name || humanize(user.role)}</strong>
          <div className="sub">{user.email}</div>
          <button
            onClick={logout}
            className="fat sub"
            style={{ marginTop: ".5rem" }}
          >
            Logout
          </button>
        </div>
      </Menu>
    );
}
