import { useState, useEffect } from "react";
import { useActionData, useNavigation } from "react-router-dom";
import Icon from "./Icon.js";
import { humanize } from "../lib/text.js";

export function Submit({ wide, text = "Save", ...rest }) {
  const busy = useNavigation().state == "submitting";
  return (
    <button
      type="submit"
      className="fat commit"
      style={wide && { width: "100%", marginBottom: "1em" }}
      {...rest}
    >
      {busy ? <span className="spinner" /> : text}
    </button>
  );
}

export const Text = (props) => (
  <Field {...props}>
    <input type="text" {...props} />
  </Field>
);

export function File(props) {
  const [text, setText] = useState("No file chosen");
  return (
    <Field name={props.name}>
      <div className="row file">
        <div>{text}</div>
        <div>
          <Icon name="change" />
          <input
            {...props}
            type="file"
            onChange={(e) => setText(e.target.files[0].name)}
          />
        </div>
      </div>
    </Field>
  );
}

export function Radios({ name, value, values }) {
  return (
    <div className="radios">
      <label>{humanize(name)}</label>
      <div>
        {values.map((v) => (
          <span key={v}>
            <input
              type="radio"
              name={name}
              value={v}
              defaultChecked={v == (value || values[0])}
              className="field"
              style={{ marginRight: ".5em" }}
            />
            <span style={{ marginRight: "1em", verticalAlign: "middle" }}>
              {humanize(v.toLowerCase()).replace(/_/g, "-")}
            </span>
          </span>
        ))}
      </div>
    </div>
  );
}

export function Field({ label, name, error, menu, children }) {
  const issues = useActionData()?.validationIssues || {};
  error = error || issues[name];
  const [maxHeight, setMaxHeight] = useState();
  useEffect(() => {
    if (menu)
      setMaxHeight(
        window.innerHeight -
          document.activeElement.parentNode.getBoundingClientRect().bottom
      );
  }, [menu]);
  return (
    <div className="labeledField">
      <label>{label || humanize(name)}</label>
      <div>
        <div style={{ position: "relative" }}>
          <div
            className={error ? "invalid field" : "field"}
            style={{
              overflow: "hidden",
              borderColor: `var(${error ? "--error" : "--dark"})`,
            }}
          >
            {children}
          </div>
          <div
            className="field menu"
            onMouseDown={(e) => e.preventDefault()}
            style={{
              display: menu ? "" : "none",
              zIndex: 1,
              marginTop: -1,
              position: "absolute",
              top: "100%",
              left: 0,
              width: "100%",
              maxHeight: maxHeight || "50vh",
              overflowY: "auto",
            }}
          >
            {menu}
          </div>
        </div>
        <small style={{ color: "var(--error)" }}>{error}</small>
      </div>
    </div>
  );
}
